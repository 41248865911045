<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk1Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk1',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-电煤招标详情'
    getBulk1Details({id: this.$route.params.id}).then(res => {
      this.tableData = [
        {text: '经营单位', value: res[0].managementName},
        {text: '运输方式', value: res[0].transportMethod},
        {text: '报价时间', value: res[0].quoteStartTime + '-' + res[0].quoteEndTime},
        {text: '港口', value: res[0].departureName + '~' + res[0].destinationName},
        {text: '地址', value: res[0].departureDetail + '~' + res[0].destinationDetail},
        {text: '商品', value: res[0].goodsName},
        {text: '数量', value: res[0].quantity},
        {text: '单位', value: res[0].measurementUnitName},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
